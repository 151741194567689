body {
  background:url(/background.jpg);
  background-size:cover;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.mysterious-logo {
   font-family: 'Mystery Quest', cursive;
   font-weight: 400;
   font-size: 3em;
   opacity: .6;
}
.subtitle {
    margin-top: 40px;
   font-family: 'PT Serif', serif;
   font-weight: bold;
   font-size: 1em;
   opacity: 0.8;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.games {
  opacity: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Flashlight Overlay */
:root {
  cursor: pointer;
  --cursorX: 50vw;
  --cursorY: 50vh;
}
:root:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  background: radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,.5) 80%,
    rgba(0,0,0,.95) 100%
  )
}